@import 'styles/mixins';
@import 'styles/variables';

.cardContainer {
  position: relative;
  width: 340px;
  margin-bottom: 25px;

  @include small-desktop-and-up {
    margin-right: 20px;
  }

  @include desktop {
    margin-right: 60px;
  }

  img {
    border-radius: 20px;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background-color: $primary-dark;
    color: $white;
    margin-top: -6px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    span {
      font-family: $avenir-heavy;
      font-size: 18px;
      line-height: 20px;
      opacity: 0.9;
    }
  }
}
