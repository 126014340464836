@import 'styles/mixins';
@import 'styles/variables';

.card {
  position: relative;
  width: 90%;
  z-index: 1;
  max-width: 540px;
  max-height: 790px;
  background-color: $subtle-bg;
  border-radius: 20px;
  margin: 0 auto;
  margin-bottom: 65px;

  @include desktop {
    width: 100%;
    margin: 0;
  }

  .header {
    height: 148px;
    background-color: $primary-dark;
    padding: 35px 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    span {
      font-family: $avenir-black;
      font-size: 14;
      letter-spacing: 3.5px;
      opacity: 0.8;
    }

    h1 {
      font-size: 50px;
      color: $white;
      line-height: 1.4;
      margin-top: 5px;

      @include small-desktop-and-up {
        font-size: 50px;
        font-size: 30px;
      }
    }
  }

  .body {
    padding: 35px 20px;

    ul {
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        svg {
          min-width: 30px;
          width: 30px;
          height: auto;
          margin-right: 20px;

          @include small-desktop-and-up {
            min-width: 50px;
            width: 50px;
          }
        }

        span {
          font-family: $avenir-heavy;
          font-size: 14px;
          text-align: left;

          @include small-desktop-and-up {
            font-size: 16px;
          }
        }
      }
    }

    button,
    a {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      background-color: $main-green;

      &:hover {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
      }
    }

    .greenBtn {
      &:hover {
        background-color: rgba(18, 223, 199, 0.9);
      }
      span {
        color: $primary-dark;
      }
    }

    .mainBtn {
      background-color: $main;
      color: $white;

      &:hover {
        background-color: rgb(63, 0, 153);
      }
    }
  }
}
