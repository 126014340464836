@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: 601px) and (max-width: 960px) {
    @content;
  }
}

@mixin tablet-and-up {
  @media screen and (min-width: 601px) {
    @content;
  }
}

@mixin small-desktop {
  @media screen and (min-width: 961px) and (max-width: 1279px) {
    @content;
  }
}

@mixin small-desktop-and-up {
  @media screen and (min-width: 960px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: 1921px) {
    @content;
  }
}
