// Fonts

$avenir-primary: 'Avenir Roman';
$avenir-heavy: 'Avenir Heavy';
$avenir-black: 'Avenir Black';
$avenir-book: 'Avenir Book';
$avenir-medium: 'Avenir Medium';
$avenir-oblique: 'Avenir Oblique';
$avenir-medium-oblique: 'Avenir MediumOblique';

// Colors

$main: #5b00db;
$primary-dark: #323643;
$dark-grey: #26282c;
$light-grey: #ebedf2;
$mid-grey: #c8ccd9;
$bg-grey: #f2f4f9;
$calcite: #fff;
$white: #ffffff;
$periwinkle: #a377fe;
$subtle-bg: #f2f4f9;
$border-grey: rgba(0, 0, 0, 0.15);
$main-green: #12dfc7;
$pastel-green: #e8fefc;

// Alerts

$success: #57c88c;
$light-success: #dffced;
$error: #ff4b4b;
$light-error: #fce7e7;
$warning: #663c00;

// Social

$facebook: #4267b2;
$twitter: #1da1f2;
$instagram: #e1306c;
