@import 'styles/mixins';
@import 'styles/variables';

.homeContainer {
  min-height: 100%;
  background-color: $white;

  .mask {
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-top: 50px;

    @include small-desktop-and-up {
      height: 650px;
    }

    .background {
      position: relative;
      height: 100%;
      width: auto;
      background-image: linear-gradient(to bottom, #5b00db 11%, #12dfc7 89%);
      padding: 40px 20px;
      text-align: center;

      @include small-desktop-and-up {
        background-image: linear-gradient(to bottom, #5b00db 25%, #12dfc7);
        padding: 40px 0 0 0;
      }

      @include small-desktop-and-up {
        padding: 70px 0 0 0;
      }
    }

    .angle {
      position: absolute;
      width: 100%;
      height: 35%;
      clip-path: polygon(0 40%, 0% 100%, 100% 100%);
      bottom: 0;
      left: 0;
      background: $white;
    }

    .landingAngle {
      background-color: $bg-grey;
    }
  }

  .content {
    position: relative;
    margin-top: -200px;

    &.landingContent {
      background-image: linear-gradient(to bottom, #f2f4f9, #fff 99%);
    }

    @include small-desktop-and-up {
      margin-top: 100px;

      &.landingContent {
        margin-top: 0;
      }
    }

    .centerDescription {
      background-color: $white;
      text-align: center;
      padding: 20px 20px 40px;

      @include tablet-and-up {
        max-width: 80%;
        margin: 0 auto;
      }

      @include small-desktop-and-up {
        max-width: 60%;
        font-size: 24px;
        padding: 40px 0 80px;
      }
    }

    .testimonial {
      position: relative;
      width: 90%;
      max-width: 1140px;
      margin: 60px auto;
      background-color: rgba(18, 223, 199, 0.1);
      border-radius: 20px;
      padding: 105px 20px 20px;
      text-align: center;

      .avatarImg {
        position: absolute;
        top: -80px;
        left: 50%;
        transform: translateX(-50%);
      }

      h3 {
        font-size: 26px;

        @include desktop {
          font-size: 40px;
          max-width: 986px;
          margin: 0 auto;
        }
      }

      span {
        font-family: $avenir-black;
        opacity: 0.8;
        letter-spacing: 3.5px;
        display: flex;
        margin: 40px 0 25px;
        justify-content: center;
      }
    }
  }

  .whiteBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 28px;
    background-color: $white;
    margin-top: -27px;
  }

  .shadow {
    position: absolute;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 95%);
    width: 100%;
    height: 80px;
    margin-top: -106px;
  }

  .coverImage {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -130px auto 30px auto;
    max-width: 1140px;

    @media screen and (min-width: 368px) {
      margin-top: -170px;

      &.landingCover {
        margin-top: -227px;
      }
    }

    @media screen and (min-width: 600px) {
      margin-top: -400px;

      &.landingCover {
        margin-top: -400px;
      }
    }

    @include small-desktop-and-up {
      margin-top: -580px;
      flex-direction: row;
      padding: 0 15px;

      &.landingCover {
        margin-top: -580px;
      }
    }

    @include desktop {
      margin-top: -550px;

      &.landingCover {
        margin-top: -550px;
      }
    }

    @media screen and (min-width: 1160px) {
      padding: 0;
    }

    .imgContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      order: 2;

      @include small-desktop-and-up {
        justify-content: inherit;
        margin-top: 0;
        order: 1;
      }

      img:first-child {
        width: 180px;
        margin-right: -130px;

        @media screen and (min-width: 390px) {
          width: 230px;
        }
      }

      img:nth-child(2) {
        width: 100%;
        width: 280px;
      }
    }

    .rightSectionContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include small-desktop-and-up {
        order: 2;
      }

      button,
      a {
        min-width: 145px;

        @include small-desktop-and-up {
          min-width: 49%;
        }
      }

      .outlinedBtn {
        margin-left: 10px;

        span {
          color: $white;
        }

        border-color: $white;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .tabs {
    position: relative;
    width: 98%;
    max-width: 1140px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    background-color: $white;

    @include tablet-and-up {
      width: 80%;
    }

    .tab {
      flex: 1;
      text-align: center;
      font-size: 12px;
      font-family: $avenir-black;
      letter-spacing: 3.5px;
      text-transform: uppercase;
      padding: 10px;
      background-color: $white;
      border: 2px solid rgb(238 241 247);
      border-bottom: none;
      color: $primary-dark;
      margin-right: 10px;

      @media screen and (min-width: 400px) {
        padding: 15px;
      }

      @include tablet-and-up {
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }

      &.nilTab {
        background-color: $primary-dark !important;
        color: $main-green !important;
        border: 2px solid $primary-dark !important;
      }

      &.active {
        background-color: rgb(238 241 247);
        color: $main;
      }
    }
  }

  .homeSection {
    background-color: rgb(238 241 247);
    padding: 40px 0 55px;
    margin-top: -1px;

    @include small-desktop-and-up {
      padding-top: 115px;
      margin-top: 0;

      &.landingSection {
        padding-top: 50px;
      }
    }

    iframe {
      border: none;
      border-radius: 10px;
      height: 250px;

      @include small-desktop-and-up {
        height: 550px;
        padding: 0 20px;
      }

      @include desktop {
        padding: 0;
      }
    }

    .nilSection {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include small-desktop-and-up {
        width: 100%;
        height: auto;
      }

      .imgContainer {
        position: absolute;
        width: 100%;
        min-height: 750px;
        max-height: 900px;

        img:first-child {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          margin-top: -1px;
          object-fit: cover;
        }

        .curveImg {
          display: none;
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 0;

          @media screen and (min-width: 1385px) {
            display: flex;
          }
        }
      }

      .shadow {
        position: absolute;
        top: 105px;
        background-image: linear-gradient(to bottom, #323643, rgba(50, 54, 67, 0.39) 50%);
        z-index: 1;
      }

      .nilContent {
        padding: 40px 0 55px;
        z-index: 1;

        @include small-desktop-and-up {
          padding-top: 115px;
        }

        h1 {
          font-size: 30px;
          color: $white;
          line-height: 1.4;

          @include small-desktop-and-up {
            font-size: 50px;
          }
        }

        p {
          max-width: 92%;
          margin: 20px auto 0;
          color: $white;
          font-size: 18px;

          @include small-desktop-and-up {
            max-width: 80%;
          }
        }
      }

      .cardContainer {
        width: 100%;
        max-width: 1140px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .smallCardContainer {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-bottom: 70px;

        @include small-desktop-and-up {
          justify-content: center;
        }

        @include desktop {
          margin: 140px 0 150px 0;
        }
      }

      .partnersContainer {
        width: 100%;
        max-width: 1140px;
        margin: 20px 0 100px;

        @include small-desktop-and-up {
          margin-bottom: 180px;
        }

        .partnersCard {
          width: 90%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin: 50px auto 0;

          @include desktop {
            width: 100%;
          }
        }
      }
    }

    .section {
      width: 90%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include small-desktop-and-up {
        width: 100%;
      }

      .subtitle {
        font-size: 16px;

        @include small-desktop-and-up {
          font-size: 20px;
        }
      }

      ul {
        display: flex;
        list-style-type: none;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;

        @media screen and (min-width: 1400px) {
          width: 100%;
          justify-content: space-evenly;
        }

        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 130px;
          margin-right: 10px;
          text-align: center;
          margin-bottom: 20px;

          // svg {
          //   filter: invert(12%) sepia(95%) saturate(5048%) hue-rotate(266deg) brightness(78%)
          //     contrast(130%);
          // }

          h6 {
            margin-top: 15px;
            font-size: 14px;
          }
        }
      }

      h1 {
        font-size: 30px;
        line-height: 1.4;
        margin-bottom: 20px;

        @include small-desktop-and-up {
          font-size: 50px;
          margin-bottom: 40px;
        }
      }

      p {
        margin: 20px 10px;
        font-size: 11px;
        max-width: 946px;

        @media screen and (min-width: 425px) {
          font-size: 14px;
        }

        @include small-desktop-and-up {
          font-size: 18px;
        }
      }
    }

    .landingSection {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      max-width: 1140px;

      h3 {
        font-size: 22px;
        max-width: 375px;
        margin: 15px auto 5px;
      }

      p {
        font-size: 16px;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        list-style-type: none;

        @include small-desktop-and-up {
          flex-direction: row;
          justify-content: space-around;
        }

        li {
          margin-bottom: 40px;

          @include small-desktop-and-up {
            width: 340px;
          }
        }
      }
    }
  }

  .animation {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding-top: 116.6%; /* 6:7 ratio*/
    border-radius: 10px;
    -webkit-box-shadow: 0 0 75px 0 rgb(91 0 219 / 15%);
    box-shadow: 0 0 75px 0 rgb(91 0 219 / 15%);

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .middleButton {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: -25px auto;

    @include tablet-and-up {
      width: 300px;
    }

    a {
      margin-bottom: 25px;
      color: $main;

      span {
        font-size: 14px;
        color: $white;
      }
    }
  }

  .mainGreenBtn {
    background-color: $main-green;

    &:hover {
      background-color: rgba(18, 223, 199, 0.9);
    }

    span {
      color: $primary-dark !important;
    }
  }

  // style for the CardWithImage component

  .aboutUs {
    background-color: rgba(18, 223, 199, 0.1);
    padding: 20px 5% 40px;
    margin-top: 10px;
    text-align: center;
    justify-content: center;

    @include small-desktop-and-up {
      padding: 0 10% 100px;
      margin-top: 10px;
    }

    h1 {
      font-size: 30px;
      line-height: 1.4;

      @include small-desktop-and-up {
        font-size: 50px;
      }
    }

    p {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      font-size: 16px;
      margin: 20px auto 0;
      max-width: 900px;

      @include small-desktop-and-up {
        font-size: 18px;
      }
    }

    .bottomIcons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style-type: none;
      margin: 0 auto;
      background-color: rgba(18, 223, 199, 0);
      padding-top: 50px;

      @include small-desktop-and-up {
        padding-top: 120px;
      }

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 230px;
        margin-bottom: 20px;

        @include desktop {
          min-width: 280px;
        }

        span:first-child {
          font-size: 14px;
          color: $main;
          opacity: 0.8;
          letter-spacing: 3.5px;
          text-transform: uppercase;
          font-family: $avenir-black;
        }

        .number {
          font-size: 24px;
          font-family: $avenir-heavy;
          line-height: 1.4;

          @include desktop {
            font-size: 50px;
          }
        }
      }
    }
  }

  .text {
    text-align: center;
    margin-bottom: 50px;
    font-size: 15px;

    @include small-desktop-and-up {
      font-size: 18px;
      padding: 20px 0;
      margin-bottom: 80px;
    }

    a {
      font-size: 16px;
      text-align: center;
      color: $main;

      @include small-desktop-and-up {
        font-size: 18px;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.85;
      }
    }
  }

  .rightSection {
    h2,
    h3 {
      color: $white;
    }

    h2 {
      font-size: 30px;
      max-width: 400px;
      margin: 0 auto;

      @include small-desktop-and-up {
        font-size: 50px;
        padding: 0 15px;
        max-width: 600px;
      }
    }

    h3 {
      font-size: 14px;
      font-family: $avenir-primary;
      font-weight: 400;
      color: #f5f0ff;
      margin-bottom: 45px !important;

      @media screen and (min-width: 419px) {
        font-size: 16px;
      }

      @include small-desktop-and-up {
        font-size: 24px;
      }
    }
  }

  .greenBtn {
    background-color: $main-green;

    &:hover {
      background-color: rgba(18, 223, 199, 0.8);
    }

    span {
      color: $primary-dark;
    }
  }
} // end of homeContainer
