@import 'styles/mixins';
@import 'styles/variables';

.container {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 50px 5% 50px 5% !important;

  @include small-desktop-and-up {
    padding: 100px 8% 100px 8% !important;
  }

  .text {
    text-align: center;

    @include small-desktop-and-up {
      text-align: left;
      padding-right: 100px;
    }

    h1 {
      font-size: 30px;
      line-height: 1.4;

      @include small-desktop-and-up {
        font-size: 50px;
      }
    }

    p {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      font-size: 15px;

      @include small-desktop-and-up {
        font-size: 18px;
      }
    }
  }

  .imgContainer {
    display: flex;
    position: relative;
    justify-content: center;
    // box-shadow: 0 0 75px 0 rgb(91 0 219 / 15%);

    @media screen and (max-width: 960px) {
      margin-top: 20px;
    }

    @include small-desktop-and-up {
      justify-content: flex-end;
    }

    img {
      position: relative;
      box-shadow: 0 0 30px 0 rgba(91, 0, 219, 0.35);
      max-width: 560px;
      border-radius: 10px;

      @include small-desktop-and-up {
        box-shadow: 0 0 75px 0 rgba(91, 0, 219, 0.35);
      }
    }
  }

  .second {
    @include small-desktop-and-up {
      padding-left: 100px;
      padding-right: 0;
      order: 2;
    }
  }

  .first {
    @include small-desktop-and-up {
      order: 1;
      justify-content: flex-start;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1140px;
    margin: 0 auto;

    ul {
      margin-top: 20px;
      font-size: 15px;

      @include small-desktop-and-up {
        font-size: 18px;
      }

      li {
        list-style: none;
        margin-top: 15px;
      }
    }
  }
}
